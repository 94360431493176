.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-item {
  flex-basis: 100%
}

.wrap {
  display: flex;
  flex-wrap: wrap
}

.caption {
  text-align: left;
  font-size: 12px;
}

.h2 {
  font-size: 15px;
}

.h3 {
  font-size: 13px;
}

.modal {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

textarea {
  width: 100%;
  height: 100%;
  resize: none;
}

.AppHeader {
  display: flex;
  justify-content: left;
  padding: 0.5% 0.5%;
  background-color: #7a8291;
  height: 7vh
}

.App {
  text-align: center;
}

.LoginForm-inputs {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.Input {
  background-color: #f5f5f5;
  border: 1px solid #0b597a;
  outline: none;
  height: 30px;
  line-height: 28px;
  font-size: 13px;
  padding: 0px 8px;
}



.TextButton {
  cursor: pointer;
  justify-content: center;
  align-content: center;
  display: inline-block;
  color: blue;
  font-size: 13px;
  text-decoration: underline;
}
